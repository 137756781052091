import { defineAsyncComponent } from 'vue';

import {
  ANNOUNCEMENT_PAGE,
  REGISTRATION_MODULE,
} from '~/features/registrations/components/constants';
import type { Category } from '~/features/search/types';
import { TICKETS_MODULE } from '~/features/tickets/constants';

export const categories = [
  {
    component: defineAsyncComponent(
      () => import('../components/TicketResult.vue'),
    ),
    icon: 'tickets',
    resultsShown: 3,
    title: 'Tickets',
    type: 'tickets',
    redirect: {
      module: TICKETS_MODULE,
      route: '/ticket',
    },
  },
  {
    component: defineAsyncComponent(
      () => import('../components/FormResult.vue'),
    ),
    icon: 'instruction',
    resultsShown: 3,
    title: 'Forms',
    type: 'form-flow-items',
    redirect: {
      module: 'forms',
      route: '/form-flows',
    },
  },
  {
    component: defineAsyncComponent(
      () => import('../components/AccessRequestAnnouncementResult.vue'),
    ),
    icon: 'registration',
    resultsShown: 3,
    title: 'Announcements',
    type: 'accessrequest-announcement-persons',
    redirect: {
      module: REGISTRATION_MODULE,
      route: ANNOUNCEMENT_PAGE,
    },
  },
  {
    component: defineAsyncComponent(
      () => import('../components/AccessRequestRegistrationResult.vue'),
    ),
    icon: 'registration',
    resultsShown: 3,
    title: 'Registrations',
    type: 'accessrequest-registrations',
    redirect: {
      module: REGISTRATION_MODULE,
      route: '/access-request',
    },
  },
] as Category[];
