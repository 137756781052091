import { useQuery } from '@tanstack/vue-query';

import type { SearchRequestBody, SearchResults } from '../types';

export function useSearchResults(model: Ref<SearchRequestBody>) {
  const __ = useTranslate();
  const { $toasty } = useNuxtApp();

  const enabled = computed(
    () => !!model.value?.searchQuery && model.value.includeTypes.length > 0,
  );

  return useQuery({
    queryKey: ['searchbar', { model }],
    enabled,
    queryFn: async () => {
      const { data, error } = await useApi<SearchResults>('/api/v2/search', {
        method: 'POST',
        body: model.value,
      });

      if (error.value)
        throw error.value;

      return data?.value;
    },
    onError() {
      $toasty.$error({
        title: __('Retrieving search results was unsuccessful'),
        message: __('Search was unsuccessful'),
        autoDismiss: 5000,
      } as any);
      return {};
    },
  });
}
